import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// importing aos
import AOS from "aos";
import "aos/dist/aos.css";
//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//Owl Carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

gsap.registerPlugin(ScrollTrigger);

export const PublicHome = () => {
  let timer;
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      timer = setInterval(() => {
        openPopUp();
      }, 5000);
      firstRender.current = false;
    }
  });

  const nav = useNavigate();
  const main = useRef();
  const tga1 = useRef();
  const steps = useRef();
  const service = useRef();

  function openPopUp() {
    clearInterval(timer);
    let pop = document.getElementById("pop");
    let popO = document.getElementById("popO");
    popO.style.display = "block";
    pop.style.display = "block";
    setTimeout(() => {
      popO.style.opacity = "1";
      pop.style.opacity = "1";
    }, 200);
  }

  function closePopUp() {
    let pop = document.getElementById("pop");
    let popO = document.getElementById("popO");
    popO.style.opacity = "0";
    pop.style.opacity = "0";
    setTimeout(() => {
      popO.style.display = "none";
      pop.style.display = "none";
    }, 200);
  }

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.from(".grid-elem", {
        opacity: "0",
        ease: "Power3.easeOut",
        y: 100,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: ".grid-elem",
        },
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.from(".tga1", {
        opacity: "0",
        ease: "Power3.easeOut",
        y: 100,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: ".tga1",
        },
      });
    }, tga1); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.from(".step-item", {
        opacity: 0,
        ease: "Power3.easeIn",
        y: 100,
        stagger: 0.2,
        duration: 1,
        scrollTrigger: {
          trigger: ".step-cont",
          scrub: 0.4,
        },
      });
    }, steps);
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="home">
        {/*Popup*/}

        {/*Popup End*/}
        <Header />
        {/*Dynamic Text Section*/}
        <section className="ftco-section ftco-no-pt dyna-text">
          <div className="container position-relative">
            <div className="main-text">
              <span className="first-text">No group classes</span>
              <span className="first-text">No e-learning courses</span>
              <span className="first-text">
                Pure mentoring with real mentors
              </span>
              <span className="first-text">
                Specially curated mentor panels
              </span>
            </div>
            <div className="tagLine text-center mt-5">
              <span className="main-tag d-block" ref={tga1}>
                <span className="tga1">Develop.&nbsp;</span>
                <span className="tga1">Breakthrough.&nbsp;</span>
                <span className="tga1">Conquer.</span>
              </span>
              <span className="tagExp d-block mt-4 fw-bold">
                Where The Focus Is On You!
              </span>
            </div>
            <img src="assets/images/balloon_1.png" alt="balloon_1" id="ball1" />
            <img src="assets/images/balloon_2.png" alt="balloon_2" id="ball2" />
          </div>
        </section>
        {/*Dynamic Text Section End*/}
        {/*Promo Banner*/}

        {/*Promo Banner End*/}
        {/*  Gyaan Domains */}
        <section className="ftco-section" id="gdom">
          <h1 id="gyaanHead" className="">
            Gyaan Domains
          </h1>
          <div className="tagdom text-center p-3">
            <span style={{ color: "#1eb2a6" }} className="fw-bold fs-5">
              Develop your interests. Find a path that suits you best and defeat
              the obstacles to reach your goals.
            </span>
          </div>
          <div className="gdom-grid p-lg-5 p-3">
            <div
              className="row mt-5 gap-lg-0 gap-xl-0"
              ref={main}
              style={{ rowGap: "1rem" }}
            >
              <div className="grid-img gyanImg col-lg-2 col-6 col-xl-2 grid-elem" />
              <div className="grid-text col-lg-2 col-6 col-xl-2 grid-elem">
                <h2>Struggling with language?</h2>
              </div>
              <div className="grid-img2 gyanImg col-lg-2 col-6 col-xl-2 grid-elem" />
              <div className="grid-text col-lg-2 col-6 col-xl-2 grid-elem">
                <h2>Love numbers but hate finance?</h2>
              </div>
              <div className="grid-img3 gyanImg col-lg-2 col-6 col-xl-2 grid-elem" />
              <div className="grid-text col-lg-2 col-6 col-xl-2 grid-elem">
                <h2>How to win at marketing?</h2>
              </div>
              <div className="grid-text col-lg-2 col-6 col-xl-2 grid-elem">
                <h2>Unable to do justice to law?</h2>
              </div>
              <div className="grid-img4 gyanImg col-lg-2 col-6 col-xl-2 grid-elem" />
              <div className="grid-text col-lg-2 col-6 col-xl-2 grid-elem">
                <h2>Not sure how to market yourself?</h2>
              </div>
              <div className="grid-img5 gyanImg col-lg-2 col-6 col-xl-2 grid-elem" />
              <div className="grid-text col-lg-2 col-6 col-xl-2 grid-elem">
                <h2>Trying to prep for a new job?</h2>
              </div>
              <div className="grid-img6 gyanImg col-lg-2 col-6 col-xl-2 grid-elem" />
              {/*<div className="grid-text col-lg-2 col-md-3 col-sm-4 col-6 col-xl-2"><h2>Wanna make better business decisions?</h2></div>*/}
            </div>
          </div>
        </section>
        {/*  Gyaan Domains End  */}
        {/* Coaching vs Mentoring */}
        <section className="ftco-section bg-light ftco-no-pt p-0">
          <div className="container-xl pt-5 mid-sect">
            <div className="top-text text-center">
              {/*<span className="subheading text-light">Coach vs Mentor</span>*/}
              <h2 className="fw-bold text-light">
                Coaching & Mentoring & Career Counselling
              </h2>
            </div>
            <div
              className="service-flex d-flex flex-lg-row flex-xl-row flex-column justify-content-center align-items-center flex-wrap gap-4 mt-5 row p-3"
              ref={service}
            >
              <div className="ser-item d-flex flex-column gap-4 text-center col-lg-3 col-xl-3 col-10 sia1">
                <div className="top-text-item" style={{ color: "#008dc7" }}>
                  Coaching
                </div>
                <div className="mid-icon-item">
                  <i className="fa-solid fa-chalkboard-user" />
                </div>
                <div className="bottom-text-item">
                  <p>
                    The practice of encouraging and motivating self-exploration
                    for individuals to achieve the best of their abilities.
                    Coaching believes that people have the answers to their own
                    problems, within them.{" "}
                  </p>
                </div>
              </div>
              <div className="ser-item d-flex flex-column gap-4  text-center col-lg-3 col-xl-3 col-10 sia1">
                <div className="top-text-item" style={{ color: "#1a9856" }}>
                  Mentoring
                </div>
                <div className="mid-icon-item">
                  <i className="fa-solid fa-user-tie" />
                </div>
                <div className="bottom-text-item">
                  <p>
                    The process of advising and training in order to develop
                    certain skills related to strategic thinking, required for
                    maximizing potential and performance. The mentors offer
                    advice and guidance based on their own experiences too.
                  </p>
                </div>
              </div>
              <div className="ser-item d-flex flex-column gap-4  text-center col-lg-3 col-xl-3 col-10 sia1">
                <div className="top-text-item" style={{ color: "#1bbfce" }}>
                  Career Counselling
                </div>
                <div className="mid-icon-item">
                  <i className="fa-solid fa-school" />
                </div>
                <div className="bottom-text-item">
                  <p>
                    {" "}
                    The systematic process of helping students to analyze their
                    strengths, interests, and skills by providing unbiased
                    advice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Coaching vs Mentoring End  */}
        {/*Mentoring Process*/}
        <div className="mentoring-sect" id="mPro">
          <div className="mentoring-cont">
            <div className="mTop-text">
              <h2 className="fw-bold">Mentoring Process</h2>
              <h5 className="subheading fw-bold">Our 5 Simple Steps</h5>
            </div>
            <div className="step-flex" ref={steps}>
              <div className="step-cont d-flex w-100 justify-content-lg-start justify-content-xl-start justify-content-center">
                <div className="step-item d-flex justify-content-start">
                  <div className="step-img1" />
                  <div className="step-text p-4">
                    <h3 className="p-0 m-0 text-light fw-bold">
                      Step&nbsp;One
                    </h3>
                    <span className="p-0 m-0 text-light fw-bold">
                      Tell us about yourself
                    </span>
                  </div>
                </div>
              </div>
              <div className="step-cont d-flex w-100 justify-content-lg-end justify-content-xl-end justify-content-center">
                <div className="step-item d-flex justify-content-start">
                  <div className="step-img2" />
                  <div className="step-text p-4">
                    <h3 className="p-0 m-0 text-light fw-bold">
                      Step&nbsp;Two
                    </h3>
                    <span className="p-0 m-0 text-light fw-bold">
                      Have a free call with one of us to get all the info you
                      wish to know
                    </span>
                  </div>
                </div>
              </div>
              <div className="step-cont d-flex w-100 justify-content-lg-start justify-content-xl-start justify-content-center">
                <div className="step-item d-flex justify-content-start">
                  <div className="step-img3" />
                  <div className="step-text p-4">
                    <h3 className="p-0 m-0 text-light fw-bold">
                      Step&nbsp;Three
                    </h3>
                    <span className="p-0 m-0 text-light fw-bold">
                      Book a PEP Talk plan that suits your needs
                    </span>
                  </div>
                </div>
              </div>
              <div className="step-cont d-flex w-100 justify-content-lg-end justify-content-xl-end justify-content-center">
                <div className="step-item d-flex justify-content-start">
                  <div className="step-img4" />
                  <div className="step-text p-4">
                    <h3 className="p-0 m-0 text-light fw-bold">
                      Step&nbsp;Four
                    </h3>
                    <span className="p-0 m-0 text-light fw-bold">
                      Get started on the sessions with our Gyaan Guides
                    </span>
                  </div>
                </div>
              </div>
              <div className="step-cont d-flex w-100 justify-content-lg-start justify-content-xl-start justify-content-center">
                <div className="step-item d-flex justify-content-start">
                  <div className="step-img5" />
                  <div className="step-text p-4">
                    <h3 className="p-0 m-0 text-light fw-bold">
                      Step&nbsp;Five
                    </h3>
                    <span className="p-0 m-0 text-light fw-bold">
                      Grow and upskill as you go!
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Mentoring Process End*/}
        {/*  Pricing Section  */}
        <section className="ftco-section ftco-no-pt pricing-sect" id="prPack">
          <div className="container mt-5">
            <div className="row justify-content-center pb-5">
              <div className="col-md-7 text-center heading-section aos-init aos-animate">
                {/*<span className="subheading text-light">Our Pricing</span>*/}
                <h2 className="mb-3 pricing-text">
                  Pricing &amp; <span>Packages</span>
                </h2>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-8 col-lg-6 col-xl-3 aos-init aos-animate price-cont">
                <div className="block-7 price-3x">
                  <div className="">
                    <span className="excerpt d-block text-center">
                      3x Growth Plan
                    </span>
                    <span className="price text-center"></span>
                    <div className="p-4 px-lg-5">
                      <ul className="text-light">
                        <li>3 Sessions</li>
                        <li>CV Guidance</li>
                      </ul>
                    </div>
                    <a
                      href="/contact-us"
                      className="btn price-btn price-btn3 d-block px-2 py-3"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-8 col-lg-6 col-xl-3 aos-init aos-animate price-cont">
                <div className="price-5x block-7">
                  <div className="">
                    <span className="excerpt d-block text-center">
                      5x Growth Plan
                    </span>
                    <span className="price text-center"></span>
                    <div className="p-4 px-lg-5">
                      <ul className="text-light">
                        <li>4 Sessions</li>
                        <li>Choice of Mentors</li>
                        <li>
                          Practical Assignments with Review, Resources and
                          Materials
                        </li>
                        <li>Access to Master Classes at Special Prices</li>
                      </ul>
                    </div>
                    <a
                      href="contact-us"
                      className="btn price-btn price-btn5 d-block px-2 py-3"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-8 col-lg-6 col-xl-3 aos-init aos-animate price-cont">
                <div className="price-10x block-7">
                  <div className="">
                    <span className="excerpt d-block text-center">
                      10x Growth Plan
                    </span>
                    <span className="price text-center"></span>
                    <div className="p-4 px-lg-5">
                      <ul className="text-light">
                        <li>6 Sessions</li>
                        <li>Choice of Mentors</li>
                        <li>
                          Practical Assignments with Review, Resources and
                          Materials
                        </li>
                        <li>Access to Master Classes at Special Prices</li>
                        <li>CV Guidance</li>
                      </ul>
                    </div>
                    <a
                      href="/contact-us"
                      className="btn price-btn price-btn10 d-block px-2 py-3"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Pricing Section End  */}
        {/*Contact Section*/}
        <div className="contact-section">
          <div className="conHome-cont d-flex flex-lg-row flex-xl-row flex-md-row gap-lg-0 gap-xl-0 gap-4 flex-column align-items-center justify-content-around">
            <div className="top-text">
              <span className="fw-bold">Tell us about yourself</span>
              <p className="mt-4 fs-5 text-light fw-bold">
                You've taken the first step towards transforming yourself!
                Please take a few minutes to fill out this form to help us help
                you!
              </p>
            </div>
            <div className="contact-btn">
              <button
                className="btn shadow fw-bold fs-5 px-3 py-2"
                onClick={() => {
                  nav("/contact-us");
                }}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
        {/*Contact Section End*/}
        {/*Testimonials*/}
        <div className="testimonials p-5" id="testm">
          <div className="testm-cont">
            <div className="test-top-text">
              <h2 className="fw-bold">Too good to be true?</h2>
            </div>
            <div className="testm-flex mt-5">
              <div className="row flex-wrap gap-4 justify-content-center">
                <div className="testm-item d-flex flex-column gap-3 col-lg-5 item">
                  <div className="ques-text">
                    <span>
                      As a BBA student, how can expert guidance help direct you
                      towards a fulfilling career path?
                    </span>
                  </div>
                  <div className="testm-text">
                    <span>
                      <i>
                        “A major issue that I have faced while pursuing my
                        interest in the field of management is that I stumbled a
                        lot of times in terms of where and how to go forward. I
                        believe expert guidance will help me find the answer to
                        those questions and it is better to walk down the path
                        with someone who already knows the way to your
                        destination.”{" "}
                      </i>
                    </span>
                  </div>
                  <div className="name-text">
                    <span>- Mohit, BBA Business Studies</span>
                  </div>
                </div>
                <div className="testm-item d-flex flex-column gap-3 col-lg-5 item">
                  <div className="ques-text">
                    <span>
                      As a fresher, how would expert guidance help you prepare
                      to work better in organizations in the future?
                    </span>
                  </div>
                  <div className="testm-text">
                    <span>
                      <i>
                        “Everyone would agree if I said adulting is anxious and
                        landing your first job and pretending to be
                        professionals is much more anxious. What if I said there
                        are mentors who can ease this process? How do they do
                        this? They normalize failure by experience sharing and
                        help us set attainable smart goals, all that we need for
                        our future organizations.”
                      </i>
                    </span>
                  </div>
                  <div className="name-text">
                    <span>- Amrutha, Psychology</span>
                  </div>
                </div>
              </div>
              <div className="viewMore d-flex w-100 px-5 mt-4 justify-content-end">
                <Link className="fw-bold shadow" to={"/testimonials"}>
                  View More...
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*Testimonials End*/}
        {/*Our Team*/}
        <div className="ourTeam" id="ourTeam">
          <div className="ourTeam-cont">
            <div className="team-top-text">
              <h2 className="fw-bold">Our Team</h2>
            </div>
            <div className="team-cont mt-5 d-flex flex-column gap-5">
              <div className="team-card">
                <div className="team-item d-flex flex-lg-row flex-column gap-4 align-items-center">
                  <div className="imgDiv">
                    <img
                      src="assets/images/anamika_team.png"
                      alt="Team Member"
                    />
                  </div>
                  <div className="team-text-div d-flex gap-3 flex-column">
                    <span className="fw-bold fs-1">Anamika Viswanathan</span>
                    <span className="fw-bold fs-5">Founder-Mentor</span>
                    <p className="fs-5 text-light">
                      Ms Anamika is a proficient and dynamic leader with over
                      two and a half decades of organisational and leadership
                      experience in Business Psychology and Advertising. Having
                      worked and engaged with premier esteemed advertising
                      agencies and trained as a Business Psychologist, she
                      serves as an independent consultant in the academic
                      sector.
                    </p>
                  </div>
                </div>
              </div>
              <div className="cc-card">
                <div className="cc-container">
                  <div className="cc-text">
                    <a
                      href="https://christuniversity.in/center/O/CONSULTING"
                      target="_blank"
                    >
                      Powered By
                    </a>
                  </div>
                  <div className="cc-logo">
                    <a
                      href="https://christuniversity.in/center/O/CONSULTING"
                      target="_blank"
                    >
                      <img
                        src="assets/images/christ_consulting.jpg"
                        alt="Christ_Consulting_Logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/*<div className="team-card">*/}
              {/*    <div className="team-item2 d-flex flex-lg-row-reverse flex-column gap-3 align-items-center shadow">*/}
              {/*        <div className="imgDiv">*/}
              {/*            <img src="assets/images/darpan_team.jpg" alt="Team Member"/>*/}
              {/*        </div>*/}
              {/*        <div className="team-text-div d-flex gap-4 flex-column">*/}
              {/*            <span className="fw-bold fs-1">Darpan Sunil</span>*/}
              {/*            <span className="fw-bold fs-5">Business Development and Marketing Chief</span>*/}
              {/*            <p className="fs-5">Darpan Sunil is an avid marketer with the motto "over delivering value". He is a third-year BCom Honors student from CHRIST (Deemed to be University) and has a keen passion for the art of marketing and designing creative strategies.</p>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              {/*<div className="team-card">*/}
              {/*    <div className="team-item2 d-flex flex-lg-row flex-column gap-4 align-items-center shadow">*/}
              {/*        <div className="imgDiv">*/}
              {/*            <img src="assets/images/Srividyaa_team.jpg" alt="Team Member"/>*/}
              {/*        </div>*/}
              {/*        <div className="team-text-div d-flex gap-4 flex-column">*/}
              {/*            <span className="fw-bold fs-1">Srividyaa</span>*/}
              {/*            <span className="fw-bold fs-5">HR and Finance Chief</span>*/}
              {/*            <p className="fs-5">Srividyaa S is a BCom Honors graduate from CHRIST (Deemed to be University) and has completed her US CMA (Awaiting Charter). She is a meticulous worker with interests ranging from content writing and creative thinking to real-time bottle-neck solving.</p>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              {/*<div className="team-card">*/}
              {/*    <div className="team-item2 d-flex flex-lg-row-reverse flex-column gap-3 align-items-center shadow">*/}
              {/*        <div className="imgDiv">*/}
              {/*            <img src="assets/images/ansh_team.jpg" alt="Team Member"/>*/}
              {/*        </div>*/}
              {/*        <div className="team-text-div d-flex gap-4 flex-column">*/}
              {/*            <span className="fw-bold fs-1">Ansh Arora</span>*/}
              {/*            <span className="fw-bold fs-5">Tech Chief</span>*/}
              {/*            <p className="fs-5">Ansh Arora is a second-year BCA student from CHRIST (Deemed to be University) and a tech enthusiast with a keen interest developing new ideas for businesses as well as engineering – construction of models as well as deployment.</p>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/*Our Team End*/}
        {/*Footer*/}
        <Footer />
        {/*Footer End*/}
      </div>
    </>
  );
};
